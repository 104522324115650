import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// components
import Home from '@/pages/Home';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/terms',
        name: 'Terms',
        component: () => import('@/components/modals/TermsModal.vue'),
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/components/modals/PrivacyModal.vue'),
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
