const FirebaseConfig = {
  apiKey: 'AIzaSyCVfTricIZxNQdDTSasc5RQCLF8vwjyg4M',
  authDomain: 'artfi-landing.firebaseapp.com',
  projectId: 'artfi-landing',
  storageBucket: 'artfi-landing.appspot.com',
  messagingSenderId: '850085173626',
  appId: '1:850085173626:web:b8cec2426db4c3c9855ec3',
  measurementId: 'G-WX1R8NPTL0',
};

export default FirebaseConfig;
