<script setup lang="ts">

</script>

<template>
  <p class="soon-link">
    <img class="soon" src="@/assets/images/icons/soon.svg" alt="soon" @click.prevent.stop>
    <slot />
  </p>
</template>

<style scoped lang="scss">
.soon-link {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: default;

  &:hover img.soon {
    opacity: 1;
  }

  img.soon {
    position: absolute;
    bottom: 100%;

    opacity: 0;
    pointer-events: none;
    touch-action: none;

    transition: opacity 0.3s ease-in-out;
  }
}
</style>
