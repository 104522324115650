import http from '@/instances/http';

const EmailApi = {
  async sendEmail(email: string): Promise<void> {
    const headers = {
      'Content-Type': 'application/json',
    };

    await http.post(
      'https://subscribe-a2egvzxnaq-uc.a.run.app',
      undefined,
      { headers, params: { email } },
    );
  },
};

export default EmailApi;
