<script setup lang="ts">

</script>

<template>
  <button>
    <slot />
  </button>
</template>

<style scoped lang="scss">
@import 'src/styles/mixins';

button {
  @include typo-button;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.75rem 2rem;
  color: $color-black;
  background: $color-white;
  border: 1px solid $color-white;
  border-radius: 0.5rem;

  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:not(:disabled):hover {
    color: $color-white;
    background: transparent;
  }
}
</style>
